* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* apex charts */
.apexcharts-legend{
  gap: 30px;
}

@media screen and (max-width: 768px) {
  .apexcharts-legend {
    gap: 10px;
  }
}

/* react-datepicker */
.react-datepicker-popper {
  z-index: 2;
}